const permissions = {
    search_placeholder: 'API veya izin adına göre ara',
    search_placeholder_without_api: 'İzin adına göre ara',
    name_column: 'İzin',
    description_column: 'Açıklama',
    api_column: 'API',
    placeholder_title: 'İzin',
    placeholder_description: 'İzin, bir kaynağa erişmek için yetki verme durumunu ifade eder (biz buna API kaynağı diyoruz).',
    edit: 'Düzenleme izni',
    delete: 'Silme izni',
    remove: 'Kaldırma izni',
    updated: 'İzin güncellendi.',
    edit_title: 'API iznini düzenle',
};
export default Object.freeze(permissions);
