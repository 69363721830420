const tabs = {
    get_started: 'Primeiros passos',
    dashboard: 'Painel',
    applications: 'Aplicativos',
    api_resources: 'Recursos da API',
    sign_in_experience: 'Experiência de login',
    connectors: 'Conectores',
    enterprise_sso: 'Enterprise SSO',
    webhooks: 'Webhooks',
    organizations: 'Organizações',
    users: 'Usuários',
    audit_logs: 'Logs',
    roles: 'Registros',
    docs: 'Documentação',
    tenant_settings: 'Configurações',
    mfa: 'Autenticação de multi-fator',
    /** UNTRANSLATED */
    customize_jwt: 'JWT Claims',
    signing_keys: 'Chaves de assinatura',
    organization_template: 'Modelo de organização',
};
export default Object.freeze(tabs);
