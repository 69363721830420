const permissions = {
    search_placeholder: 'APIまたは権限名で検索',
    search_placeholder_without_api: '権限名で検索',
    name_column: '権限',
    description_column: '説明',
    api_column: 'API',
    placeholder_title: '権限',
    placeholder_description: '権限はリソース（APIリソースと呼んでいます）にアクセスするための承認を指します。',
    edit: '編集権限',
    delete: '削除権限',
    remove: '除去権限',
    updated: '許可が更新されました。',
    edit_title: 'APIの権限を編集',
};
export default Object.freeze(permissions);
