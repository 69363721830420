const permissions = {
    search_placeholder: '通過 API 或權限名稱搜索',
    search_placeholder_without_api: '通過權限名稱搜索',
    name_column: '權限',
    description_column: '描述',
    api_column: 'API',
    placeholder_title: '權限',
    placeholder_description: '權限是指訪問資源的授權（我們稱其為 API 資源）。',
    edit: '編輯權限',
    delete: '刪除權限',
    remove: '移除權限',
    updated: '權限已更新。',
    edit_title: '編輯 API 權限',
};
export default Object.freeze(permissions);
