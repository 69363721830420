const permissions = {
    search_placeholder: 'Поиск по имени API или разрешению',
    search_placeholder_without_api: 'Поиск по наименованию разрешения',
    name_column: 'Разрешение',
    description_column: 'Описание',
    api_column: 'API',
    placeholder_title: 'Разрешение',
    placeholder_description: 'Разрешение относится к авторизации доступа к ресурсу (мы называем это ресурсом API).',
    edit: 'Разрешение на редактирование',
    delete: 'Разрешение на удаление',
    remove: 'Разрешение на удаление',
    updated: 'Разрешение обновлено.',
    edit_title: 'Редактировать разрешение API',
};
export default Object.freeze(permissions);
