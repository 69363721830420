const tabs = {
    get_started: '開始上手',
    dashboard: '儀表板',
    applications: '全部應用',
    api_resources: 'API 資源',
    sign_in_experience: '登錄體驗',
    connectors: '連接器',
    enterprise_sso: '企業SSO',
    webhooks: 'Webhooks',
    organizations: '組織',
    users: '用戶管理',
    audit_logs: '審計日誌',
    roles: '角色',
    docs: '文檔',
    tenant_settings: '租戶設置',
    mfa: '多重認證',
    /** UNTRANSLATED */
    customize_jwt: 'JWT Claims',
    signing_keys: '簽署密鑰',
    organization_template: '組織模板',
};
export default Object.freeze(tabs);
