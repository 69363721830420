const tabs = {
    get_started: 'Iniziare',
    dashboard: 'Dashboard',
    applications: 'Applicazioni',
    api_resources: 'Risorse API',
    sign_in_experience: 'Esperienza di accesso',
    connectors: 'Connettori',
    enterprise_sso: 'SSO Enterprise',
    webhooks: 'Webhooks',
    organizations: 'Organizzazioni',
    users: 'Gestione utenti',
    audit_logs: 'Registri di verifica',
    roles: 'Ruoli',
    docs: 'Documenti',
    tenant_settings: 'Impostazioni',
    mfa: 'Autenticazione multi-fattore',
    /** UNTRANSLATED */
    customize_jwt: 'JWT Claims',
    signing_keys: 'Chiavi di firma',
    organization_template: 'Modello di organizzazione',
};
export default Object.freeze(tabs);
