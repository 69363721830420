const tabs = {
    get_started: 'Get started',
    dashboard: 'Dashboard',
    applications: 'Applications',
    api_resources: 'API resources',
    sign_in_experience: 'Sign-in experience',
    connectors: 'Connectors',
    enterprise_sso: 'Enterprise SSO',
    webhooks: 'Webhooks',
    organizations: 'Organizations',
    users: 'User management',
    audit_logs: 'Audit logs',
    roles: 'Roles',
    docs: 'Docs',
    tenant_settings: 'Settings',
    mfa: 'Multi-factor auth',
    customize_jwt: 'JWT Claims',
    signing_keys: 'Signing keys',
    organization_template: 'Organization template',
};
export default Object.freeze(tabs);
